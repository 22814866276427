import { FilterByCnae } from 'src/app/models/types';
import { TableCnaeAndCompanyStateModel } from './table-cnae-and-company.model';

//===============================//
//      BUSCAR POR EMPRESA       //
//===============================//

export class CountCompanyByCnae {
  static readonly type = '[Table Cnae and Company] count company by cnae';

  /** */
  constructor(public filterByCnae: FilterByCnae) {}
}

//===============================//
//        Atualizar Loja         //
//===============================//

export class UpdateStore {
  static readonly type = '[Table Cnae and Company] update store';

  /** */
  constructor(public store: Partial<TableCnaeAndCompanyStateModel>) {}
}

//===============================//
//         Redefinir Loja        //
//===============================//

export class ResetTableCnaeAndCompanyStore {
  static readonly type = '[Table Cnae and Company] reset table company';
}
