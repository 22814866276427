import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { QueryOfContacts, RequestData, RequestDataCount } from 'src/app/models/types';
import { getQueryParamCnaeAndRole, getQueryParamCompanyId, getQueryParamCompanyNameAndRole, getQueryParamContactsCount, getQueryParamRoles } from 'src/app/utils/contact/query-parms';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContactsService {
  private readonly headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`).append('Access-Control-Allow-Origin', '*');

  private paginationSettings!: Record<string, string>;

  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Deve consumir a api para buscar a lista de cnaes com a quantidade de contatos
   * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
   * @returns uma lista de canes e a quantidade de contatos
   */
  getContactsCount(query: QueryOfContacts): Promise<Array<RequestDataCount>> {
    const params = getQueryParamContactsCount(query);
    return firstValueFrom(this.http.get<Array<RequestDataCount>>(`${environment.capturas}/api/v1/contacts/count`, { params, headers: this.headers }));
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de cargo
   * @param query atributos de pesquisa ( cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de contatos
   */
  async getContactsByRoles(query: QueryOfContacts, offset = 0, limit = 50, isPaginationAction: boolean): Promise<RequestData> {
    const params = getQueryParamRoles(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.capturas}/api/v1/contacts/find-by-role`, { params, headers: this.headers }).pipe(tap((requestData) => (this.paginationSettings = requestData.meta.settings)));

    return await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de empresas
   * @param query atributos de pesquisa ( cnaes, estados, cidades )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de contatos
   */
  async getContactsByCompanyId(query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamCompanyId(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.capturas}/api/v1/companies/${query.companyId}/contacts`, { params, headers: this.headers }).pipe(tap((requestData) => (this.paginationSettings = requestData.meta.settings)));

    await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos por filtros de empresas e cargo
   * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @param isPaginationAction define se deve ser passado os configuração para as paginações seguintes
   * @returns uma lista de contatos
   */
  async getContactsByCnaeAndRole(query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<RequestData> {
    const params = getQueryParamCnaeAndRole(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.capturas}/api/v1/contacts`, { params, headers: this.headers }).pipe(tap((requestData) => (this.paginationSettings = requestData.meta.settings)));

    return await firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para retornar a lista de contatos filtrados por nome da empresa e cargo
   * @param componentId id do componente que chamou o serviço
   * @param query atributos de pesquisa ( nome da empres e cargo )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @param isPaginationAction define se deve ser passado os configuração para as paginações seguintes
   * @returns uma lista de contatos
   */
  async getContactsByCompanyNameAndRole(query: QueryOfContacts, offset: number, limit: number, isPaginationAction: boolean): Promise<void> {
    const params = getQueryParamCompanyNameAndRole(query, offset, limit, isPaginationAction ? this.paginationSettings : null);
    const observableRequest = this.http.get<RequestData>(`${environment.capturas}/api/v1/contacts`, { params, headers: this.headers }).pipe(tap((requestData) => (this.paginationSettings = requestData.meta.settings)));

    await firstValueFrom(observableRequest);
  }
}
