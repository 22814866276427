import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { QueryOfCompanies, RequestDataCompany, RequestDataCount } from 'src/app/models/types';
import { getQueryParamCompaniesByCnae, getQueryParamCompaniesByName, getQueryParamCompanyCount } from 'src/app/utils/company/query-parms';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompaniesService {
  private readonly headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`).append('Access-Control-Allow-Origin', '*');

  private paginationSettings!: Record<string, string>;

  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Deve consumir a api para buscar a lista de cnaes com a quantidade de empresas
   * @param query atributos de pesquisa ( cnaes, estados, cidades )
   * @returns uma lista de canes e a quantidade de empresas
   */
  getCompanyCount(query: QueryOfCompanies): Promise<Array<RequestDataCount>> {
    const params = getQueryParamCompanyCount(query);
    return firstValueFrom(this.http.get<Array<RequestDataCount>>(`${environment.capturas}/api/v1/companies/count`, { params, headers: this.headers }));
  }

  /**
   * Deve consumir a api para buscar a lista de empresas
   * @param query atributos de pesquisa ( cnaes, estados, cidades )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de empresas
   */
  getCompaniesByCnae(query: QueryOfCompanies, offset = 0, limit = 50, isPaginationAction: boolean): Promise<RequestDataCompany> {
    const params = getQueryParamCompaniesByCnae(query, offset, limit, isPaginationAction ? this.paginationSettings : null);

    const observableRequest = this.http.get<RequestDataCompany>(`${environment.capturas}/api/v1/companies`, { params, headers: this.headers }).pipe(
      tap((contact) => {
        this.paginationSettings = contact.meta.settings;
      }),
    );

    return firstValueFrom(observableRequest);
  }

  /**
   * Deve consumir a api para buscar a lista de empresas
   * @param query atributos de pesquisa ( nome da empresa )
   * @param offset quantidade de registros que devem ser ignorados antes do resultado
   * @param limit quantidades de contatos que devem ser na lista
   * @returns uma lista de empresas
   */
  getCompaniesByName(query: QueryOfCompanies, offset = 0, limit = 50, isPaginationAction: boolean): Promise<RequestDataCompany> {
    const params = getQueryParamCompaniesByName(query, offset, limit, isPaginationAction ? this.paginationSettings : null);

    const observableRequest = this.http.get<RequestDataCompany>(`${environment.capturas}/api/v1/companies`, { params, headers: this.headers }).pipe(
      tap((contact) => {
        this.paginationSettings = contact.meta.settings;
      }),
    );

    return firstValueFrom(observableRequest);
  }
}
