import { FilterByCnaeAndRole } from 'src/app/models/types';
import { TableCnaeAndContactStateModel } from './table-cnae-and-contact.model';

//===============================//
//      BUSCAR POR CONTATOS       //
//===============================//

export class CountContactByCnae {
  static readonly type = '[Table Cnae and Contact] count contact by cnae';

  /** */
  constructor(public filterByCnaeAndRole: FilterByCnaeAndRole) {}
}

//===============================//
//        Atualizar Loja         //
//===============================//

export class UpdateStore {
  static readonly type = '[Table Cnae and Contact] update store';

  /** */
  constructor(public store: Partial<TableCnaeAndContactStateModel>) {}
}

//===============================//
//         Redefinir Loja        //
//===============================//

export class ResetTableCnaeAndContactStore {
  static readonly type = '[Table Cnae and Contact] reset table company';
}
