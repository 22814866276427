import { Item } from '@techramper/monster-web-kit-core/dist/types/components/ms-select/ms-select-interface';

export type FiltersStateModel = {
  listOfSectors: Array<Item>;
  listOfCanes: Array<Item>;
  listOfStates: Array<Item>;
  listOfCities: Array<Item>;

  formFilterLoading: boolean;
};

export const filtersStateModelDefault: FiltersStateModel = {
  listOfSectors: [],
  listOfCanes: [],
  listOfStates: [],
  listOfCities: [],

  formFilterLoading: false,
};
