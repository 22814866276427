import { HttpParams } from '@angular/common/http';
import { QueryOfContacts } from 'src/app/models/types';

/**
 * Deve montar os query params para buscar para buscar a lista de cnaes com a quantidade de contatos
 * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
 * @returns o objeto query params da request
 */
export function getQueryParamContactsCount(query: QueryOfContacts): HttpParams {
  let params = new HttpParams();

  if (query.cnaes?.length) params = params.set('cnaes', query.cnaes.join(','));
  if (query.roles?.length) params = params.set('roles', query.roles.join(','));
  if (query.states?.length) params = params.set('states', query.states.join(','));
  if (query.cities?.length) params = params.set('cities', query.cities?.join(',') || '');
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  return params;
}

/**
 * Deve montar os query params para buscar contatos por pessoa
 * @param query atributos de pesquisa ( cargo )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @param paginationSettings configuração da paginação
 * @returns o objeto query params da request
 */
export function getQueryParamRoles(query: QueryOfContacts, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.roles?.length) params = params.set('roles', query.roles.join(','));
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}

/**
 * Deve montar os query params para buscar contatos por empresa
 * @param query atributos de pesquisa ( cnaes, estados, cidades )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @returns o objeto query params da request
 */
export function getQueryParamCompanyId(query: QueryOfContacts, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.roles?.length) params = params.set('role', query.roles.join(','));
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}

/**
 * Deve montar os query params para buscar contatos por empresa e contatos
 * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @param paginationSettings configuração da paginação
 * @returns o objeto query params da request
 */
export function getQueryParamCnaeAndRole(query: QueryOfContacts, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.cnaes?.length) params = params.set('cnae', query.cnaes.join(','));
  if (query.roles?.length) params = params.set('roles', query.roles.join(','));
  if (query.states?.length) params = params.set('states', query.states.join(','));
  if (query.cities?.length) params = params.set('cities', query.cities?.join(',') || '');
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}

/**
 * Deve montar os query params para buscar contatos por nome da empresa e cargo
 * @param query atributos de pesquisa ( nome da empresa e cargo )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @param paginationSettings configuração da paginação
 * @returns o objeto query params da request
 */
export function getQueryParamCompanyNameAndRole(query: QueryOfContacts, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.companyName) params = params.set('company-name', query.companyName);
  if (query.roles?.length) params = params.set('roles', query.roles.join(','));
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}
