import { Contact, Meta, QueryOfContacts } from 'src/app/models/types';

export type TableContactStateModel = {
  contacts: Array<Contact>;
  pagination: Meta | null;
  cacheQueries: QueryOfContacts | null;
  paginationLoading: boolean;
  currentPage: number;
};

export const tableContactStateModelDefault: TableContactStateModel = {
  contacts: [],
  pagination: null,
  cacheQueries: null,
  paginationLoading: false,
  currentPage: 1,
};
