import { Component, inject, OnInit } from '@angular/core';
import { CaptureAuthService } from './core/services/capture-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly captureAuthService: CaptureAuthService = inject(CaptureAuthService);

  protected hasAuth = false;

  //FIXME - Deve ser removido ao realizar a implementação de authenticação

  /**
   * Deve Realizar o login no capturas por enquando
   */
  ngOnInit(): void {
    this.captureAuthService.login().then(() => (this.hasAuth = true));
  }
}
