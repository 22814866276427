import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

type State = { uf: string; description: string };
type City = { id: string; name: string; uf: string };

@Injectable()
export class AddressesService {
  private readonly headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`).append('Access-Control-Allow-Origin', '*');

  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Consome a api para obter todos os estados do País
   * @returns retorna uma lista de estados
   */
  async findAllStates(): Promise<Array<State>> {
    return await firstValueFrom(this.http.get<Array<State>>(`${environment.capturas}/api/v1/states`, { headers: this.headers }));
  }

  /**
   * Consome a api para obter todos as cidades do estado selecionado
   * @returns retorna uma lista de cidades
   */
  async findAllCities(uf: string): Promise<Array<City>> {
    return await firstValueFrom(this.http.get<Array<City>>(`${environment.capturas}/api/v1/cities/${uf}`, { headers: this.headers }));
  }
}
