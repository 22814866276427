import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CompanyQueryType } from 'src/app/models/enums';
import { Company, QueryOfCompanies } from 'src/app/models/types';
import { TableCompanyStateModel, tableCompanyStateModelDefault } from './table-company.model';
import { CompaniesService } from 'src/app/core/services/companies.service';
import { FindCompany, FindCompanyPagination, ResetTableCompanyStore, UpdateStore } from './table-company.actions';

@State<TableCompanyStateModel>({ name: new StateToken<TableCompanyStateModel>('TableCompany'), defaults: tableCompanyStateModelDefault })
@Injectable()
export class TableCompanyState {
  // Injetado o serviço para lidar Empresas
  private readonly companiesService: CompaniesService = inject(CompaniesService);

  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa e verifica se exsite empresas
   */
  @Selector() static hasCompanies(state: TableCompanyStateModel): boolean {
    return !!state.companies.length;
  }

  /**
   * Observa o loading de carregamento da paginação
   */
  @Selector() static paginationLoading(state: TableCompanyStateModel): boolean {
    return state.paginationLoading;
  }

  /**
   * Observa a pagina ativa da paginação
   */
  @Selector() static currentPage(state: TableCompanyStateModel): number {
    return state.currentPage;
  }

  /**
   * Observa as queries usadas para buscar empresas anteriormente
   */
  @Selector() static cacheQueries(state: TableCompanyStateModel): QueryOfCompanies | null {
    return state.cacheQueries;
  }

  /**
   * Observa os dados de paginação
   */
  @Selector() static totalPages(state: TableCompanyStateModel): number {
    return state.pagination?.pageCount || 0;
  }

  /**
   * Observa a lista de empresas
   */
  @Selector() static companies(state: TableCompanyStateModel): Array<Company> {
    return state.companies;
  }

  //===============================//
  //    Buscar por nome empresa    //
  //===============================//

  /**
   * Consome o sirviço para buscar por nome de empresas
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindCompany)
  async findCompany(ctx: StateContext<TableCompanyStateModel>, payload: FindCompany): Promise<void> {
    ctx.setState({ ...ctx.getState(), currentPage: 1 });

    const cache = { ...payload.queryOfCompanies };

    if (cache.type === CompanyQueryType.COMPANY_NAME) {
      const request = await this.companiesService.getCompaniesByName(cache, 0, 50, false);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, companies: request.data || [], pagination: request.meta || null });
    }

    if (cache.type === CompanyQueryType.CNAE) {
      const request = await this.companiesService.getCompaniesByCnae(cache, 0, 50, false);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, companies: request.data || [], pagination: request.meta || null });
    }
  }

  /**
   * Consome o sirviço para buscar por nome de empresas
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindCompanyPagination)
  async findCompanyPagination(ctx: StateContext<TableCompanyStateModel>, payload: FindCompanyPagination): Promise<void> {
    ctx.setState({ ...ctx.getState(), paginationLoading: true, currentPage: payload.page });

    const offset = 50 * (payload.page - 1);
    const cache = ctx.getState().cacheQueries as QueryOfCompanies;

    if (cache.type === CompanyQueryType.COMPANY_NAME) {
      const request = await this.companiesService.getCompaniesByName(cache, offset, 50, true);
      ctx.setState({ ...ctx.getState(), companies: request.data || [], pagination: request.meta || null, paginationLoading: false });
    }

    if (cache.type === CompanyQueryType.CNAE) {
      const request = await this.companiesService.getCompaniesByCnae(cache, offset, 50, true);
      ctx.setState({ ...ctx.getState(), companies: request.data || [], pagination: request.meta || null, paginationLoading: false });
    }
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<TableCompanyStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }

  /**
   * Deve consumir o serviço para resetar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(ResetTableCompanyStore)
  async resetTableCompanyStore(ctx: StateContext<TableCompanyStateModel>, payload: ResetTableCompanyStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...tableCompanyStateModelDefault });
  }
}
