import { Company, Meta, QueryOfCompanies } from 'src/app/models/types';

export type TableCompanyStateModel = {
  companies: Array<Company>;
  pagination: Meta | null;
  cacheQueries: QueryOfCompanies | null;
  paginationLoading: boolean;
  currentPage: number;
};

export const tableCompanyStateModelDefault: TableCompanyStateModel = {
  companies: [],
  pagination: null,
  cacheQueries: null,
  paginationLoading: false,
  currentPage: 1,
};
