import { FilterByCnae, FilterByCnaeAndRole, FilterByCompanyName, FilterByRole } from 'src/app/models/types';
import { FiltersStateModel } from './filters.model';

//===============================//
//         Listar Cnaes          //
//===============================//

export class FindAllCnaes {
  static readonly type = '[Filters] find all cnaes';
}

//===============================//
//         Listar Setores        //
//===============================//

export class FindAllSectors {
  static readonly type = '[Filters] find all sectors';
}

//===============================//
//         Listar Estados        //
//===============================//

export class FindAllStates {
  static readonly type = '[Filters] find all states';
}

//===============================//
//         Listar Cidades        //
//===============================//

export class FindAllCities {
  static readonly type = '[Filters] find all cities';

  /** */
  constructor(public uf: string) {}
}

//===============================//
//     Busca por Nome Empresa    //
//===============================//

export class SearchByCompanyName {
  static readonly type = '[Filters] search by company name';

  /** */
  constructor(public filterByCompanyName: FilterByCompanyName) {}
}

//===============================//
//     Busca por Cnae + Cargo    //
//===============================//

export class SearchByCnaeAndRole {
  static readonly type = '[Filters] search by cnae and role';

  /** */
  constructor(public filterByCnaeAndRole: FilterByCnaeAndRole) {}
}

//===============================//
//         Busca por Cnae        //
//===============================//

export class SearchByCnae {
  static readonly type = '[Filters] search by cnae';

  /** */
  constructor(public filterByCnae: FilterByCnae) {}
}

//===============================//
//         Busca por Cargo        //
//===============================//

export class SearchByRole {
  static readonly type = '[Filters] search by role';

  /** */
  constructor(public filterByRole: FilterByRole) {}
}

//===============================//
//       Limpar Formulário       //
//===============================//

export class CleanForm {
  static readonly type = '[Filters] Clean filtros';

  /** */
  constructor() {}
}

//===============================//
//        Atualizar Store        //
//===============================//

export class UpdateStore {
  static readonly type = '[Filters] update store';

  /** */
  constructor(public store: Partial<FiltersStateModel>) {}
}
