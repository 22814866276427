import { HttpParams } from '@angular/common/http';
import { QueryOfCompanies } from 'src/app/models/types';

/**
 * Deve montar os query params para buscar a lista de cnaes com a quantidade de empresas
 * @param query atributos de pesquisa ( cnaes, estados, cidades e cargo )
 * @returns o objeto query params da request
 */
export function getQueryParamCompanyCount(query: QueryOfCompanies): HttpParams {
  let params = new HttpParams();

  if (query.cnaes?.length) params = params.set('cnaes', query.cnaes.join(','));
  if (query.states?.length) params = params.set('states', query.states.join(','));
  if (query.cities?.length) params = params.set('cities', query.cities?.join(',') || '');

  return params;
}

/**
 * Deve montar os query params para buscar lista de empresas por cnae
 * @param query atributos de pesquisa ( cnaes, estados e cidades )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @param paginationSettings configuração da paginação
 * @returns o objeto query params da request
 */
export function getQueryParamCompaniesByCnae(query: QueryOfCompanies, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.cnaes?.length) params = params.set('cnaes', query.cnaes.join(','));
  if (query.states?.length) params = params.set('states', query.states.join(','));
  if (query.cities?.length) params = params.set('cities', query.cities?.join(',') || '');
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}

/**
 * Deve montar os query params para buscar lista de empresas por nome da empresa
 * @param query atributos de pesquisa ( nome da empresa )
 * @param offset quantidade de registros que devem ser ignorados antes do resultado
 * @param limit quantidades de contatos que devem ser na lista
 * @param paginationSettings configuração da paginação
 * @returns o objeto query params da request
 */
export function getQueryParamCompaniesByName(query: QueryOfCompanies, offset: number, limit: number, paginationSettings: Record<string, string> | null): HttpParams {
  let params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

  if (query.companyName) params = params.set('name', query.companyName);
  if (query.reliability?.length) params = params.set('reliability', query.reliability.join(','));

  if (paginationSettings) {
    Object.keys(paginationSettings).forEach((key) => {
      params = params.set(key, paginationSettings[key]);
    });
  }

  return params;
}
