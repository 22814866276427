import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Item } from '@techramper/monster-web-kit-core/dist/types/components/ms-select/ms-select-interface';
import { FiltersStateModel, filtersStateModelDefault } from './filters.model';
import { CnaesService } from 'src/app/core/services/cnaes.service';
import { AddressesService } from 'src/app/core/services/addresses.service';
import { CleanForm, FindAllCities, FindAllCnaes, FindAllSectors, FindAllStates, SearchByCnae, SearchByCnaeAndRole, SearchByCompanyName, SearchByCompanyNameAndRole, SearchByRole, UpdateStore } from './filters.actions';
import { firstValueFrom, Observable } from 'rxjs';
import { FindCompany, ResetTableCompanyStore } from '../table-company/table-company.actions';
import { SetComponentView } from '../capture.actions';
import { CompanyQueryType, ComponentView, ContactQueryType } from 'src/app/models/enums';
import { CountCompanyByCnae, ResetTableCnaeAndCompanyStore } from '../table-cnae-and-company/table-cnae-and-company.actions';
import { QueryOfCompanies, QueryOfContacts } from 'src/app/models/types';
import { TableCnaeAndCompanyState } from '../table-cnae-and-company/table-cnae-and-company.state';
import { FindContact } from '../table-contact/table-contact.actions';
import { TableContactState } from '../table-contact/table-contact.state';
import { TableCompanyState } from '../table-company/table-company.state';
import { CountContactByCnae } from '../table-cnae-and-contact/table-cnae-and-contact.actions';
import { TableCnaeAndContactState } from '../table-cnae-and-contact/table-cnae-and-contact.state';

@State<FiltersStateModel>({ name: new StateToken<FiltersStateModel>('Filters'), defaults: filtersStateModelDefault })
@Injectable()
export class FiltersState {
  // Injetado o serviço para lidar Setores e Cnaes
  private readonly cnaesService: CnaesService = inject(CnaesService);

  // Injetado o serviço para lidar Setores e Cnaes
  private readonly addressesService: AddressesService = inject(AddressesService);

  // Observa um item da loja
  protected readonly hasCompanies$: Observable<boolean> = inject(Store).select(TableCompanyState.hasCompanies);

  // Observa um item da loja
  protected readonly hasContacts$: Observable<boolean> = inject(Store).select(TableContactState.hasContacts);

  // Observa um item da loja
  protected readonly hasCountCompanyByCnaes$: Observable<boolean> = inject(Store).select(TableCnaeAndCompanyState.hasCountCompanyByCnaes);

  // Observa um item da loja
  protected readonly hasCountContactByCnaes$: Observable<boolean> = inject(Store).select(TableCnaeAndContactState.hasCountContactByCnaes);

  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa lista de cnaes
   */
  @Selector() static listOfCanes(state: FiltersStateModel): Array<Item> {
    return state.listOfCanes;
  }

  /**
   * Observa lista de setores
   */
  @Selector() static listOfSectors(state: FiltersStateModel): Array<Item> {
    return state.listOfSectors;
  }

  /**
   * Observa lista de Estados
   */
  @Selector() static listOfStates(state: FiltersStateModel): Array<Item> {
    return state.listOfStates;
  }

  /**
   * Observa lista de Cidades
   */
  @Selector() static listOfCities(state: FiltersStateModel): Array<Item> {
    return state.listOfCities;
  }

  /**
   * Observa o loading de carregamento
   */
  @Selector() static formFilterLoading(state: FiltersStateModel): boolean {
    return state.formFilterLoading;
  }

  //===============================//
  //          Buscar Cnaes         //
  //===============================//

  /**
   * Deve consumir o serviço para buscar por cnaes
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindAllCnaes)
  async findAllCnaes(ctx: StateContext<FiltersStateModel>, payload: FindAllCnaes): Promise<void> {
    const classesAndCnaes = await this.cnaesService.findAllGroupsClassesAndCnaes();
    const cnaes = classesAndCnaes.flatMap((category) => category.classesCnae.flatMap((classCnae) => classCnae.cnaes));

    const listOfCanes = cnaes.map((cnae) => {
      return { label: `${cnae.id}: ${cnae.description}`, tag: cnae.id, tooltip: cnae.description, data: cnae.id };
    });

    ctx.setState({ ...ctx.getState(), listOfCanes: listOfCanes });
  }

  //===============================//
  //         Buscar Setores        //
  //===============================//

  /**
   * Deve consumir o serviço para buscar por cnaes
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindAllSectors)
  async findAllSectors(ctx: StateContext<FiltersStateModel>, payload: FindAllSectors): Promise<void> {
    const sectorsAndSections = await this.cnaesService.findAllSectorsAndSections();
    const sectors = sectorsAndSections.map((section) => section.sectors).flat();

    const listOfSectors = sectors.map((sector, index) => {
      return { label: `${sector.id}: ${sector.description}`, tag: sector.id, tooltip: sector.description, data: sector.id };
    });

    ctx.setState({ ...ctx.getState(), listOfSectors: listOfSectors });
  }

  //===============================//
  //         Buscar Estados        //
  //===============================//

  /**
   * Deve consumir o serviço para buscar por estados
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindAllStates)
  async findAllStates(ctx: StateContext<FiltersStateModel>, payload: FindAllStates): Promise<void> {
    const states = await this.addressesService.findAllStates();

    const listOfStates = states.map((state) => {
      return { label: `(${state.uf}) ${state.description}`, tag: state.uf, tooltip: state.description, data: state.uf };
    });

    ctx.setState({ ...ctx.getState(), listOfStates: listOfStates });
  }

  //===============================//
  //         Buscar Cidades        //
  //===============================//

  /**
   * Deve consumir o serviço para buscar por estados
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindAllCities)
  async findAllCities(ctx: StateContext<FiltersStateModel>, payload: FindAllCities): Promise<void> {
    const cities = await this.addressesService.findAllCities(payload.uf);

    const listOfCities = cities.map((city) => {
      return { label: city.name, data: city.id };
    });

    ctx.setState({ ...ctx.getState(), listOfCities: listOfCities });
  }

  //===============================//
  //  Buscar por Nome da Empresa   //
  //===============================//

  /**
   * Debeve realizar a busca por nome da empresa
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SearchByCompanyName)
  async findByCompanyName(ctx: StateContext<FiltersStateModel>, payload: SearchByCompanyName): Promise<void> {
    ctx.setState({ ...ctx.getState(), formFilterLoading: true });

    await firstValueFrom(ctx.dispatch(new FindCompany({ ...payload.filterByCompanyName, type: CompanyQueryType.COMPANY_NAME } as QueryOfCompanies)));

    const hasCompanies = await firstValueFrom(this.hasCompanies$);

    if (hasCompanies) {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.COMPANY)));
    } else {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.NO_CONTENT_STATE_MESSAGE)));
    }

    ctx.setState({ ...ctx.getState(), formFilterLoading: false });
  }

  //===============================//
  //     Busca por Name + Cargo    //
  //===============================//

  /**
   * Debeve realizar a busca por company name + cargo
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SearchByCompanyNameAndRole)
  async searchByCompanyNameAndRole(ctx: StateContext<FiltersStateModel>, payload: SearchByCompanyNameAndRole): Promise<void> {
    ctx.setState({ ...ctx.getState(), formFilterLoading: true });

    await firstValueFrom(ctx.dispatch(new FindContact({ ...payload.filterByCompanyNameAndRole, type: ContactQueryType.COMPANY_AND_ROLE } as QueryOfContacts)));

    const hasContacts = await firstValueFrom(this.hasContacts$);

    if (hasContacts) {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.PEOPLE)));
    } else {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.NO_CONTENT_STATE_MESSAGE)));
    }

    ctx.setState({ ...ctx.getState(), formFilterLoading: false });
  }

  //===============================//
  //     Busca por Cnae + Cargo    //
  //===============================//

  /**
   * Debeve realizar a busca por cnae + cargo
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SearchByCnaeAndRole)
  async searchByCnaeAndRole(ctx: StateContext<FiltersStateModel>, payload: SearchByCnaeAndRole): Promise<void> {
    ctx.setState({ ...ctx.getState(), formFilterLoading: true });
    await firstValueFrom(ctx.dispatch(new CountContactByCnae(payload.filterByCnaeAndRole)));

    const hasCountContactByCnaes = await firstValueFrom(this.hasCountContactByCnaes$);

    if (hasCountContactByCnaes) {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.CNAE_AND_PEOPLE)));
    } else {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.NO_CONTENT_STATE_MESSAGE)));
    }

    ctx.setState({ ...ctx.getState(), formFilterLoading: false });
  }

  //===============================//
  //         Buscar por Cnae       //
  //===============================//

  /**
   * Debeve realizar a busca por cnae
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SearchByCnae)
  async searchByCnae(ctx: StateContext<FiltersStateModel>, payload: SearchByCnae): Promise<void> {
    ctx.setState({ ...ctx.getState(), formFilterLoading: true });
    await firstValueFrom(ctx.dispatch(new CountCompanyByCnae(payload.filterByCnae)));

    const hasCountCompanyByCnaes = await firstValueFrom(this.hasCountCompanyByCnaes$);

    if (hasCountCompanyByCnaes) {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.CNAE_AND_COMPANY)));
    } else {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.NO_CONTENT_STATE_MESSAGE)));
    }

    ctx.setState({ ...ctx.getState(), formFilterLoading: false });
  }

  //===============================//
  //        Buscar por Cargo       //
  //===============================//

  /**
   * Debeve realizar a busca por cargo
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SearchByRole)
  async searchByRole(ctx: StateContext<FiltersStateModel>, payload: SearchByRole): Promise<void> {
    ctx.setState({ ...ctx.getState(), formFilterLoading: true });

    await firstValueFrom(ctx.dispatch(new FindContact({ ...payload.filterByRole, type: ContactQueryType.ROLE } as QueryOfContacts)));

    const hasContacts = await firstValueFrom(this.hasContacts$);

    if (hasContacts) {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.PEOPLE)));
    } else {
      await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.NO_CONTENT_STATE_MESSAGE)));
    }

    ctx.setState({ ...ctx.getState(), formFilterLoading: false });
  }

  //===============================//
  //        Resetar Filtros        //
  //===============================//

  /**
   * Deve consumir o serviço para atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(CleanForm)
  async cleanForm(ctx: StateContext<FiltersStateModel>, payload: CleanForm): Promise<void> {
    await firstValueFrom(ctx.dispatch(new ResetTableCompanyStore()));
    await firstValueFrom(ctx.dispatch(new ResetTableCnaeAndCompanyStore()));
    await firstValueFrom(ctx.dispatch(new SetComponentView(ComponentView.EMPTY_STATE_MESSAGE)));
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve consumir o serviço para atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<FiltersStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }
}
