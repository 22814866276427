import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { RequestDataCount, QueryOfContacts } from 'src/app/models/types';
import { TableCnaeAndContactStateModel, tableCnaeAndContactStateModelDefault } from './table-cnae-and-contact.model';
import { ContactsService } from 'src/app/core/services/contacts.service';
import { TableContactStateModel } from '../table-contact/table-contact.model';
import { CountContactByCnae, ResetTableCnaeAndContactStore, UpdateStore } from './table-cnae-and-contact.actions';
import { ContactQueryType } from 'src/app/models/enums';

@State<TableCnaeAndContactStateModel>({ name: new StateToken<TableCnaeAndContactStateModel>('TableCnaeAndContact'), defaults: tableCnaeAndContactStateModelDefault })
@Injectable()
export class TableCnaeAndContactState {
  // Injetado o serviço para lidar contatos
  private readonly contactsService: ContactsService = inject(ContactsService);

  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa e verifica se exsite um contador por cnae
   */
  @Selector() static hasCountContactByCnaes(state: TableCnaeAndContactStateModel): boolean {
    return !!state.countContactByCnaes.length;
  }

  /**
   * Observa a lista com a quantidade de empresa por cnae
   */
  @Selector() static countContactByCnaes(state: TableCnaeAndContactStateModel): Array<RequestDataCount> {
    return state.countContactByCnaes;
  }

  /**
   * Observa as queries usadas para buscar empresas anteriormente
   */
  @Selector() static cacheQueries(state: TableContactStateModel): QueryOfContacts | null {
    return state.cacheQueries;
  }

  //===============================//
  //    Buscar por nome empresa    //
  //===============================//

  /**
   * Consome o sirviço para buscar a quantidade de empresa por cnae
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(CountContactByCnae)
  async countContactByCnae(ctx: StateContext<TableCnaeAndContactStateModel>, payload: CountContactByCnae): Promise<void> {
    const cache = { ...payload.filterByCnaeAndRole, type: ContactQueryType.CNAE_AND_ROLE };
    const request = await this.contactsService.getContactsCount(cache);

    ctx.setState({ ...ctx.getState(), cacheQueries: cache, countContactByCnaes: request || [] });
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<TableCnaeAndContactStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }

  /**
   * Deve consumir o serviço para resetar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(ResetTableCnaeAndContactStore)
  async resetTableCnaeAndContactStore(ctx: StateContext<TableCnaeAndContactStateModel>, payload: ResetTableCnaeAndContactStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...tableCnaeAndContactStateModelDefault });
  }
}
