export enum EmailReliabilityEnum {
  HIGH_RELIABILITY = 'high',
  MEDIUM_RELIABILITY = 'medium',
  LOW_RELIABILITY = 'low',
  NO_RELIABILITY = 'no_reliability',
}

export enum DisabledReason {
  INFO = 'info',
  DANGER = 'danger',
  TIMER = 'timer',
}

export enum ContactQueryType {
  NAME_AND_ROLE = 'NAME_AND_ROLE',
  ROLE = 'ROLE',
  COMPANY_AND_ROLE = 'COMPANY_AND_ROLE',
  CNAE_AND_ROLE = 'CNAE_AND_ROLE',
}

export enum CompanyQueryType {
  COMPANY_NAME = 'COMPANY_NAME',
  CNAE = 'CNAE',
}

export enum ModalAction {
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
}

export enum ComponentView {
  PEOPLE = 'PEOPLE',
  COMPANY = 'COMPANY',
  CNAE_AND_COMPANY = 'CNAE_AND_COMPANY',
  CNAE_AND_PEOPLE = 'CNAE_AND_PEOPLE',
  EMPTY_STATE_MESSAGE = 'EMPTY_STATE_MESSAGE',
  NO_CONTENT_STATE_MESSAGE = 'NO_CONTENT_STATE_MESSAGE',
}
