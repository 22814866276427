import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const authGuard = async (): Promise<boolean> => {
  // Injetado o serviço para lidar com roteamento
  const router = inject(Router);

  // Deve obter os dados do usuário quando logado
  // FIXME - Pegar o token para verificar sessão

  const accessToken = 'dwqd dwq dqw d';

  if (accessToken !== null) {
    return true;
  } else {
    void router.navigate(['/login']);
  }

  return false;
};
