import { QueryOfContacts } from 'src/app/models/types';
import { TableContactStateModel } from './table-contact.model';

//===============================//
//        BUSCAR CONTATOS        //
//===============================//

export class FindContact {
  static readonly type = '[Table Contact] find contact';

  /** */
  constructor(public qeryOfContacts: QueryOfContacts) {}
}

export class FindContactPagination {
  static readonly type = '[Table Contact] find contact with pagination action';

  /** */
  constructor(public page: number) {}
}

//===============================//
//        Atualizar Loja         //
//===============================//

export class UpdateStore {
  static readonly type = '[Table Contact] update store';

  /** */
  constructor(public store: Partial<TableContactStateModel>) {}
}

//===============================//
//         Redefinir Loja        //
//===============================//

export class ResetTableContactStore {
  static readonly type = '[Table Contact] reset table company';
}
