import { ComponentView } from 'src/app/models/enums';
import { CaptureStateModel } from './capture.model';

//===============================//
//       Definir Componente      //
//===============================//

export class SetComponentView {
  static readonly type = '[Capture] set company view';

  /** */
  constructor(public componentView: ComponentView) {}
}

//===============================//
//        Atualizar Store        //
//===============================//

export class UpdateStore {
  static readonly type = '[Capture] update store';

  /** */
  constructor(public store: Partial<CaptureStateModel>) {}
}
