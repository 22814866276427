import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CaptureStateModel, captureStateModelDefault } from './capture.model';
import { SetComponentView, UpdateStore } from './capture.actions';
import { ComponentView } from 'src/app/models/enums';

@State<CaptureStateModel>({ name: new StateToken<CaptureStateModel>('Capture'), defaults: captureStateModelDefault })
@Injectable()
export class CaptureState {
  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa qual lista deve ser apresentada
   */
  @Selector() static currentComponentView(state: CaptureStateModel): ComponentView {
    return state.currentComponentView;
  }

  //===============================//
  //         Definir a Tela        //
  //===============================//

  /**
   * Deve atualizar o estado com o componente que deve ser apresentado
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(SetComponentView)
  async setComponentView(ctx: StateContext<CaptureStateModel>, payload: SetComponentView): Promise<void> {
    ctx.setState({ ...ctx.getState(), currentComponentView: payload.componentView });
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve consumir o serviço para atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<CaptureStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }
}
