import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const noAuthGuard = async (): Promise<boolean> => {
  // Injetado o serviço para lidar com roteamento
  const router = inject(Router);

  // Deve obter o token do usuário quando logado
  // FIXME - Pegar o token para verificar sessão

  const accessToken = null;

  if (accessToken === null) {
    return true;
  }

  void router.navigate(['/pages/capturas']);

  return false;
};
