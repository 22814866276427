import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CaptureAuthService {
  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Consome a api para obter todos as grupos, classes ou cnaes
   * @returns retorna uma lista de grupos, classes ou cnaes
   */
  async login(): Promise<void> {
    //FIXME - Remover quando for implementado a autenticação

    const credential = {
      password: '1b664b47-b088-4503-8d9b-2744141e25c8',
      identifier: 'bigdata-b2b',
      limitContacts: 100,
      extras: {},
    };

    const result = await firstValueFrom(this.http.post<{ accessToken: string; refreshToken: string }>(`${environment.capturas}/api/v1/auth/login`, credential));

    sessionStorage.setItem('accessToken', result.accessToken);
  }
}
