import { enableProdMode, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { noAuthGuard } from './core/guards/no-auth.guard';
import { authGuard } from './core/guards/auth.guard';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment.development';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CaptureState } from './core/store/capture/capture.state';
import { CnaesService } from './core/services/cnaes.service';
import { AddressesService } from './core/services/addresses.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CaptureAuthService } from './core/services/capture-auth.service';
import { CommonModule } from '@angular/common';
import { CompaniesService } from './core/services/companies.service';
import { FiltersState } from './core/store/capture/filters/filters.state';
import { TableCompanyState } from './core/store/capture/table-company/table-company.state';
import { TableCnaeAndCompanyState } from './core/store/capture/table-cnae-and-company/table-cnae-and-company.state';
import { TableContactState } from './core/store/capture/table-contact/table-contact.state';
import { ContactsService } from './core/services/contacts.service';
import { TableCnaeAndContactState } from './core/store/capture/table-cnae-and-contact/table-cnae-and-contact.state';

if (environment.production) {
  enableProdMode();
}

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [noAuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
    canActivate: [noAuthGuard],
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
    canActivate: [authGuard],
  },
];

@NgModule({
  declarations: [AppComponent],
  providers: [CaptureAuthService, CnaesService, AddressesService, CompaniesService, ContactsService],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    NgxsModule.forRoot([CaptureState, FiltersState, TableCompanyState, TableCnaeAndCompanyState, TableContactState, TableCnaeAndContactState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
