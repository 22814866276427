import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Contact, QueryOfContacts } from 'src/app/models/types';
import { TableContactStateModel, tableContactStateModelDefault } from './table-contact.model';
import { FindContact, FindContactPagination, ResetTableContactStore, UpdateStore } from './table-contact.actions';
import { ContactsService } from 'src/app/core/services/contacts.service';
import { ContactQueryType } from 'src/app/models/enums';

@State<TableContactStateModel>({ name: new StateToken<TableContactStateModel>('TableContact'), defaults: tableContactStateModelDefault })
@Injectable()
export class TableContactState {
  // Injetado o serviço para lidar Contatos
  private readonly contactsService: ContactsService = inject(ContactsService);

  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa e verifica se exsite contatos
   */
  @Selector() static hasContacts(state: TableContactStateModel): boolean {
    return !!state.contacts.length;
  }

  /**
   * Observa o loading de carregamento da paginação
   */
  @Selector() static paginationLoading(state: TableContactStateModel): boolean {
    return state.paginationLoading;
  }

  /**
   * Observa a pagina ativa da paginação
   */
  @Selector() static currentPage(state: TableContactStateModel): number {
    return state.currentPage;
  }

  /**
   * Observa as queries usadas para buscar contatos anteriormente
   */
  @Selector() static cacheQueries(state: TableContactStateModel): QueryOfContacts | null {
    return state.cacheQueries;
  }

  /**
   * Observa os dados de paginação
   */
  @Selector() static totalPages(state: TableContactStateModel): number {
    return state.pagination?.pageCount || 0;
  }

  /**
   * Observa a lista de contatos
   */
  @Selector() static contacts(state: TableContactStateModel): Array<Contact> {
    return state.contacts;
  }

  //===============================//
  //        Buscar por Cargo       //
  //===============================//

  /**
   * Consome o sirviço para buscar por cargo
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindContact)
  async findContact(ctx: StateContext<TableContactStateModel>, payload: FindContact): Promise<void> {
    ctx.setState({ ...ctx.getState(), currentPage: 1 });

    const cache = { ...payload.qeryOfContacts };

    if (cache.type === ContactQueryType.ROLE) {
      const request = await this.contactsService.getContactsByRoles(cache, 0, 50, false);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null });
    }

    if (cache.type === ContactQueryType.CNAE_AND_ROLE) {
      const request = await this.contactsService.getContactsByCnaeAndRole(cache, 0, 50, false);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null });
    }

    if (cache.type === ContactQueryType.COMPANY_AND_ROLE) {
      const request = await this.contactsService.getContactsByCompanyNameAndRole(cache, 0, 50, false);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null });
    }
  }

  /**
   * Consome o sirviço para buscar por cargo
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(FindContactPagination)
  async findContactPagination(ctx: StateContext<TableContactStateModel>, payload: FindContactPagination): Promise<void> {
    ctx.setState({ ...ctx.getState(), paginationLoading: true, currentPage: payload.page });

    const offset = 50 * (payload.page - 1);
    const cache = ctx.getState().cacheQueries as QueryOfContacts;

    if (cache.type === ContactQueryType.ROLE) {
      const request = await this.contactsService.getContactsByRoles(cache, offset, 50, true);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null, paginationLoading: false });
    }

    if (cache.type === ContactQueryType.CNAE_AND_ROLE) {
      const request = await this.contactsService.getContactsByCnaeAndRole(cache, offset, 50, true);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null, paginationLoading: false });
    }

    if (cache.type === ContactQueryType.COMPANY_AND_ROLE) {
      const request = await this.contactsService.getContactsByCompanyNameAndRole(cache, offset, 50, true);
      ctx.setState({ ...ctx.getState(), cacheQueries: cache, contacts: request.data || [], pagination: request.meta || null, paginationLoading: false });
    }
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<TableContactStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }

  /**
   * Deve consumir o serviço para resetar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(ResetTableContactStore)
  async resetTableContactStore(ctx: StateContext<TableContactStateModel>, payload: ResetTableContactStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...tableContactStateModelDefault });
  }
}
