import { QueryOfContacts, RequestDataCount } from 'src/app/models/types';

export type TableCnaeAndContactStateModel = {
  cacheQueries: QueryOfContacts | null;
  countContactByCnaes: Array<RequestDataCount>;
};

export const tableCnaeAndContactStateModelDefault: TableCnaeAndContactStateModel = {
  countContactByCnaes: [],
  cacheQueries: null,
};
