import { QueryOfCompanies } from 'src/app/models/types';
import { TableCompanyStateModel } from './table-company.model';

//===============================//
//        BUSCAR EMPRESAS        //
//===============================//

export class FindCompany {
  static readonly type = '[Table Company] find company';

  /** */
  constructor(public queryOfCompanies: QueryOfCompanies) {}
}

export class FindCompanyPagination {
  static readonly type = '[Table Company] find company with pagination action';

  /** */
  constructor(public page: number) {}
}

//===============================//
//        Atualizar Loja         //
//===============================//

export class UpdateStore {
  static readonly type = '[Table Company] update store';

  /** */
  constructor(public store: Partial<TableCompanyStateModel>) {}
}

//===============================//
//         Redefinir Loja        //
//===============================//

export class ResetTableCompanyStore {
  static readonly type = '[Table Company] reset table company';
}
