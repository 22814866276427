import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

type GroupClasseAndCnae = { id: string; description: string; classesCnae: Array<{ id: string; description: string; cnaes: Array<{ id: string; description: string }> }> };
type SectorAndSection = { id: string; description: string; sectors: Array<{ id: string; description: string }> };

@Injectable()
export class CnaesService {
  private readonly headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`).append('Access-Control-Allow-Origin', '*');

  /**
   * Construtor
   * @param http injeta o provider para lidar com requisições http
   */
  constructor(private http: HttpClient) {}

  /**
   * Consome a api para obter todos as grupos, classes ou cnaes
   * @returns retorna uma lista de grupos, classes ou cnaes
   */
  async findAllGroupsClassesAndCnaes(): Promise<Array<GroupClasseAndCnae>> {
    return await firstValueFrom(this.http.get<Array<GroupClasseAndCnae>>(`${environment.capturas}/api/v1/cnaes/groups-classes-and-cnaes`, { headers: this.headers }));
  }

  /**
   * Consome a api para obter todos as setores e sessões
   * @returns retorna uma lista de setores e sessões
   */
  async findAllSectorsAndSections(): Promise<Array<SectorAndSection>> {
    return await firstValueFrom(this.http.get<Array<SectorAndSection>>(`${environment.capturas}/api/v1/cnaes/sectors-and-sections`, { headers: this.headers }));
  }
}
