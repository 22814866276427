import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CompanyQueryType } from 'src/app/models/enums';
import { QueryOfCompanies, RequestDataCount } from 'src/app/models/types';
import { CompaniesService } from 'src/app/core/services/companies.service';
import { TableCnaeAndCompanyStateModel, tableCnaeAndCompanyStateModelDefault } from './table-cnae-and-company.model';
import { CountCompanyByCnae, ResetTableCnaeAndCompanyStore, UpdateStore } from './table-cnae-and-company.actions';
import { TableCompanyStateModel } from '../table-company/table-company.model';

@State<TableCnaeAndCompanyStateModel>({ name: new StateToken<TableCnaeAndCompanyStateModel>('TableCnaeAndCompany'), defaults: tableCnaeAndCompanyStateModelDefault })
@Injectable()
export class TableCnaeAndCompanyState {
  // Injetado o serviço para lidar Empresas
  private readonly companiesService: CompaniesService = inject(CompaniesService);

  //===============================//
  //      Seletores de Estado      //
  //===============================//

  /**
   * Observa e verifica se exsite um contador por cnae
   */
  @Selector() static hasCountCompanyByCnaes(state: TableCnaeAndCompanyStateModel): boolean {
    return !!state.countCompanyByCnaes.length;
  }

  /**
   * Observa a lista com a quantidade de empresa por cnae
   */
  @Selector() static countCompanyByCnaes(state: TableCnaeAndCompanyStateModel): Array<RequestDataCount> {
    return state.countCompanyByCnaes;
  }

  /**
   * Observa as queries usadas para buscar empresas anteriormente
   */
  @Selector() static cacheQueries(state: TableCompanyStateModel): QueryOfCompanies | null {
    return state.cacheQueries;
  }

  //===============================//
  //    Buscar por nome empresa    //
  //===============================//

  /**
   * Consome o sirviço para buscar a quantidade de empresa por cnae
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(CountCompanyByCnae)
  async countCompanyByCnae(ctx: StateContext<TableCnaeAndCompanyStateModel>, payload: CountCompanyByCnae): Promise<void> {
    const cache = { ...payload.filterByCnae, type: CompanyQueryType.CNAE };
    const request = await this.companiesService.getCompanyCount(cache);

    ctx.setState({ ...ctx.getState(), cacheQueries: cache, countCompanyByCnaes: request || [] });
  }

  //===============================//
  //        Atualizar loja         //
  //===============================//

  /**
   * Deve atualizar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(UpdateStore)
  async updateStore(ctx: StateContext<TableCnaeAndCompanyStateModel>, payload: UpdateStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...payload.store });
  }

  /**
   * Deve consumir o serviço para resetar a loja
   * @param ctx contexto do estado fornecido às ações no estado
   * @param payload dados transmitidos pela a ação
   */
  @Action(ResetTableCnaeAndCompanyStore)
  async resetTableCnaeAndCompanyStore(ctx: StateContext<TableCnaeAndCompanyStateModel>, payload: ResetTableCnaeAndCompanyStore): Promise<void> {
    ctx.setState({ ...ctx.getState(), ...tableCnaeAndCompanyStateModelDefault });
  }
}
