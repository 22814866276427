import { QueryOfCompanies, RequestDataCount } from 'src/app/models/types';

export type TableCnaeAndCompanyStateModel = {
  cacheQueries: QueryOfCompanies | null;
  countCompanyByCnaes: Array<RequestDataCount>;
};

export const tableCnaeAndCompanyStateModelDefault: TableCnaeAndCompanyStateModel = {
  countCompanyByCnaes: [],
  cacheQueries: null,
};
